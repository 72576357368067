import { post } from '@/utils/request'

//获取列表
export function getList(data) {
    return post('psi/ingredient/listClass', data)
}
// 添加分类
export function getAdd(data) {
    return post('psi/ingredient/addClass', data)
}
//修改分类
export function getEdit(data) {
    return post('psi/ingredient/editClass', data)
}
//删除分类
export function getDel(data) {
    return post('psi/ingredient/delClass', data)
}
//分类下拉
export function getState(data) {
    return post('psi/ingredient/productSta', data)
}
